<template>
  <div>
    <b-card>
      <table class="w-100">
        <tr>
          <th class="pb-50 d-lg-flex">
            <feather-icon
              icon="AlertTriangleIcon"
              class="mr-75 d-none d-lg-flex"
            />
            <span class="font-weight-bold">Masalah</span>
          </th>
          <td class="pb-50 text-capitalize">
            {{ result.feedback.problem }}
          </td>
        </tr>
        <tr>
          <th class="pb-50 d-lg-flex">
            <feather-icon
              icon="AwardIcon"
              class="mr-75 d-none d-lg-flex"
            />
            <span class="font-weight-bold">Solusi</span>
          </th>
          <td class="pb-50 text-capitalize">
            {{ result.feedback.expected_solution }}
          </td>
        </tr>
      </table>
      <b-carousel
        id="gallery"
        controls
        indicators
      >
        <b-carousel-slide
          v-for="(image, indexImg) in result.feedback.images"
          :key="indexImg"
          :img-src="image"
        />
      </b-carousel>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCarousel,
    BCarouselSlide,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      type: Object,
    },
  },
}
</script>
