<template>
  <div>
    <b-card v-if="result.order">
      <b-row>
        <b-col cols="12" lg="2">
          <b-img :src="result.order.service_image" width="100" alt="Card image cap" />
        </b-col>
        <b-col cols="12" lg="10">
          <table class="w-100">
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="FileTextIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Nomor Invoice</span>
              </th>
              <td class="pb-50">
                {{ result.order.invoice_number ? result.order.invoice_number : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="CheckIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Nama Jasa</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.order.service_name ? result.order.service_name : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="StarIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Nama Paket</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.order.package_name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="AlertTriangleIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Masalah</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.problem }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="AwardIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Solusi</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.expected_solution }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="AwardIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Pembeli</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.order.buyer.name }} - {{ result.order.buyer.email }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="AwardIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Talenta</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.order.talent.name }} - {{ result.order.talent.email }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    
    <b-card>
      <b-carousel id="gallery" controls indicators>
        <b-carousel-slide v-for="(image, indexImg) in result.images" :key="indexImg" :img-src="image" />
      </b-carousel>
    </b-card>

    <!-- <chat-log :chat-data="chats"/> -->

    <b-card>
      <div class="chat-container">
        <div class="chat-body">
          <div v-for="(chat, index) in chats" :key="index">
            <div>{{ chat.date }}</div>
            <div v-for="(item, itemIndex) in chat.chats" :key="itemIndex">
              <div :class="{'message': true, 'bot-message': item.sender_type === 'buyer', 'user-message': item.sender_type === 'talent'}">
                <div class="sender">{{ item.sender_type }}</div>
                <img :src="item.content_file" alt="Product Image" style="max-width: 300px;" v-if="item.content_file" />
                <div class="message-content">{{ item.content_text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    
  </div>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'
import ChatLog from '@/components/Order/ChatLog.vue'

export default {
  components: {
    BCard,
    BCarousel,
    BCarouselSlide,
    BRow,
    BCol,
    BImg,
    ChatLog
  },
  data() {
    return {
      loading: false,
    }
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      type: Object,
    },
    chats: ""
  },
}
</script>






<style scoped>


    .chat-container {
      max-width: 600px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .chat-header {
      background-color: #f2f2f2;
      padding: 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .chat-header h2 {
      margin: 0;
    }

    .chat-body {
      padding: 20px;
      height: 500px;
          overflow-y: scroll;
    }

    .message {
      margin-bottom: 20px;
    }

    .message .sender {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .message .message-content {
      background-color: #f2f2f2;
      padding: 10px;
      border-radius: 5px;
    }

    .user-message {
      text-align: right;
    }

    .user-message .message-content {
      background-color: #DCF8C6;
    }

    .bot-message {
      text-align: left;
    }

    .bot-message .message-content {
      background-color: #fff;
    }

    .input-container {
      margin-top: 20px;
    }

    .input-container input[type="text"] {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    .input-container input[type="submit"] {
      margin-top: 10px;
      background-color: #4CAF50;
      color: #fff;
      border: none;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
    }

</style>
