<template>
  <div>
    <b-row>
      <!-- Information Complain -->
      <b-col
        cols="12"
        lg="8"
      >
        <b-tabs>
          <b-tab
            active
            title="Komplain"
          >
          
            <ComplaintUser :result="result" :chats="chats" />
          </b-tab>
          <b-tab
            v-if="result.feedback"
            title="Feedback"
          >
            <ComplaintFeedback :result="result" />
          </b-tab>
        </b-tabs>
      </b-col>
      <!-- Information Sender & Action Komplain -->
      <b-col
        cols="12"
        lg="4"
        class="mt-lg-4"
      >
        <!-- Information Winner -->
        <b-card
          v-if="result.winner !== null"
        >
          <b-card-title>Pemenang</b-card-title>
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  Pemenang : {{ result.winner == 'buyer' ? 'Pembeli' : 'Talenta' }}
                </h4>
                <span class="card-text">Admin Note: {{ result.admin_note }}</span>
              </div>
            </div>
          </div>
        </b-card>
        <!-- Information Sender -->
        <b-card
          v-if="result.sender"
        >
          <div class="d-flex justify-content-start">
            <b-img
              :src="result.sender.photo_url"
              width="80"
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ result.sender.name ? result.sender.name : '-' }}
                </h4>
                <span class="card-text">{{ result.sender.email }}</span>
              </div>
            </div>
          </div>
        </b-card>
        <!-- Action Komplain -->

      
        <b-card
          
        >
          <div>
            <label for="select-default">Pemenang Komplain</label>
            <b-form-select
              id="select-default"
              v-model="formPayload.winner"
              :options="options"
            />
          </div>
          <div>
            <label for="textarea-default">Keterangan</label>
            <b-form-textarea
              id="textarea-default"
              v-model="formPayload.description"
              placeholder="Keterangan"
              rows="3"
            />
          </div>
          <div class="text-right mt-2">
            <b-button
              v-if="checkPermission('action komplain') && isLoading == false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="createItem"
            >
              Simpan
            </b-button>
            <b-spinner
              v-else
              class="mb-1"
              variant="primary"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BFormTextarea,
  BFormSelect,
  BButton,
  BTabs,
  BTab,
  BCardTitle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import ComplaintUser from '@/components/Complaint/ComplaintUser.vue'
import ComplaintFeedback from '@/components/Complaint/ComplaintFeedback.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BFormTextarea,
    BFormSelect,
    BButton,
    BTabs,
    BTab,
    ComplaintUser,
    ComplaintFeedback,
    BCardTitle
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      result: {},
      isLoading: false,
      formPayload: {
        winner: 'buyer',
        description: '',
      },
      options: [
        { value: 'buyer', text: 'Pembeli' },
        { value: 'talent', text: 'Talenta' },
      ],
      chats: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    cleanUpForm() {
      this.formPayload = {
        winner: 'buyer',
        description: '',
      }
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      return form
    },
    createItem() {
      const form = this.preparePayload()
      this.isLoading = true

      this.$http.post(`/admin/complain/${this.id}/set-action`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.getData()
          successNotification(this, 'Success', 'Pemenang komplain berhasil di tambahkan.')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
          }
          this.isLoading = false
        })
    },
    getData() {
      this.$http.get(`/admin/complain/${this.id}`)
        .then(response => {
          this.result = response.data.data
          this.$http.get(`/admin/chat?conversation_uuid=${this.result.conversation_uuid}`)
            .then(res => {
              console.log('resarea')
              console.log(res)
              this.chats = res.data.data.chats
            })

        })
    },
  },
}
</script>
